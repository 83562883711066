import {ApiService} from '@/services/ApiService'
import http from '@/http-common'

const URL = '/api/tariff'

class TariffService extends ApiService {
    constructor(){
        super()
    }
    getUrl(){
        return URL
    }
    downloadPDF(id){
        return http.get(`${URL}/download/${id}`, {'responseType': 'arraybuffer'})
    }
}

export default new TariffService()