<template>
<div class="row">
    <div class="col-sm-3">
        {{$tc('navigation.commodities', 1)}}   
    </div>
    <div class="col-sm-1" style="padding-left: 2.9rem">
        {{$t('form.new')}}
    </div>
    <div class="col-sm-7">
        <div class="row">
            <div class="col-sm-5">
                   {{$tc('sales.buying_rates', 1)}}
            </div>
            <div class="col-sm-5">
                   {{$tc('sales.sales_rates', 1)}}
            </div>
        </div>
    </div>
                         
</div>  
<!-- header -->
<!-- commodity -->
<div class="row mt-1" :class="{'mb-4' : tariffBased, 'mb-5' : !tariffBased}" v-for="(contractcommodity,index) in thiscontractcommodity" :key="index">
    <div class="col-sm-3 commodity-column">
        <FormItem id="commodity_" type="select-multiple-fields" v-model="contractcommodity.commodities" :options="allCommodities" :content="thiscontractcommodity" :disabled="tariffBased" />
    </div>
    <div class="col-sm-1 pe-0">
        <div class="d-inline-block pe-3" style="min-width: 2rem">
            <template v-if="contractcommodity.measurement_conditional_description">
                <i class="fa fa-question-circle popover-trigger"></i>
                <div class="popover-bubble" v-html="contractcommodity.measurement_conditional_description"></div>
            </template>
        </div>
        <FormItem :id="'slider-'+index" type="slider" v-model="contractcommodity.condition_id" :options="[1,2]" @onchange="(e) => validateAutofill(e)" class="d-inline-block" :disabled="tariffBased" />
    </div>
    <div class="col-sm-7">
        <div class="row">
            <div class="col-sm-5">
                <FormItem :id="'buying_'+index+'_'+contractcommodity.id" type="currency-and-unit" v-model="thiscontractcommodity[index]" inputGroupType="purchase_price" sizeItem="12" :disabled="tariffBased"
                          :options="{ 'currencies': currencies, 'calculation_units': calc_units}" :required="true" @onchange="setCalcUnits(contractcommodity); validate($event)" :calcUnitFields="calcUnitFields" />
            </div>
            <div class="col-sm-5">
                <FormItem :id="'sales_'+index+'_'+contractcommodity.id" type="currency-and-unit" v-model="thiscontractcommodity[index]" inputGroupType="sales_price" sizeItem="12"
                          :options="{ 'currencies': currencies, 'calculation_units': calc_units}" :required="true" @onchange="validate($event)" :calcUnitFields="calcUnitFields" />
            </div>
            <div class="col-sm-auto" v-if="!tariffBased">
                <a class="btn btn-sm text-danger float-right" title="Click to delete commodity" v-show="!tariffBased" @click="deleteContractCommodity(index, true)">
                    <i class="far fa-trash-alt"></i>
                </a>
            </div>
               
        </div>
    </div>
    
    
</div>

<div class="row mt-4 justify-content-center" v-if="!tariffBased">
    <a class="btn btn-sm btn-primary w-25 mb-4" title="Click to add commodity" @click="addContractCommodity()">
        <span class="fa fa-plus"></span> &nbsp;{{$t('form.add', [$tc('navigation.commodities', 1)])}}
    </a>
</div>
                        
</template>

<script>
    export default {
        props: ['initialContractcommodity', 'index','allCommodities', 'currencies', 'calc_units', 'tariffBased'],
        name: 'ContractcommodityListComponent',
        computed: {
            thiscontractcommodity: {
                get() {
                    return this.initialContractcommodity
                }
            }
        },
        watch: {
            initialContractcommodity: {
                deep: true,
                handler(initialContractcommodity) {
                    this.$emit('contractcommoditychange', initialContractcommodity)
                }
            }
        },
        data() {
            return {
                tariffs:[],
                calcUnitFields: ['currency_id', 'calculation_unit_id'],
                submitted: false,
                loading: false,
                invalid: {}
            }
        },
       methods: {
           validate(e){
                let el = e.target;
                if(el.value) { 
                    if(el.nodeName != "SELECT")
                        el.classList.add('is-valid');
                    el.classList.remove('is-invalid');
                }
                else el.classList.remove('is-valid');
            },
            validateAutofill(e){
                this.validate(e);
                this.autofillConditions()
            },
            addCommodity(st){
                st.commodities.push({'id': ''});
            },
            deleteCommodity(st, index){
                st.commodities.splice(index, 1);
            },

            addContractCommodity() {
                this.thiscontractcommodity.push({
                    condition_id: '2',
                    commodity_id: null,
                    sales_calculation_unit_id: null,
                    purchase_calculation_unit_id: null,
                    measurement_conditional_description: null,
                    sales_price: '',
                    sales_price_currency_id: window.defaultCurrency,
                    purchase_price: '',
                    purchase_price_currency_id: window.defaultCurrency,
                    tariffs : [],
                    commodities: [{'id': ''}]
                });
            },
            deleteContractCommodity(index, askForConfirm = false) {
                if(askForConfirm && confirm("Are you sure you want to delete this commodity?")) this.thiscontractcommodity.splice(index, 1);
                if (!askForConfirm) this.thiscontractcommodity.splice(index, 1);
            },
            checkIfAlreadyChosen(commodity){
                let comms1 = [], comms2 = [];
                this.thiscontractcommodity.forEach(tsc => {
                    if(comms1.includes(tsc.commodity_id)){
                        comms2.push(tsc.commodity_id);
                    }
                    else{
                        comms1.push(tsc.commodity_id);
                    }
                })
                if(comms2.includes(commodity.id)){
                    return true;
                }
                return false;
            },
            autofillConditions(){
                let comms = [], length = this.thiscontractcommodity.length;
                document.querySelectorAll('#accordion-' + this.index + ' .condition').forEach(el => el.disabled = false);
                for(let i = 0; i < length; i++) {
                    let dupeId = comms.findIndex(comm => (comm.id == this.thiscontractcommodity[i].commodity_id && comm.id !== ''));
                    if(dupeId >= 0){
                        let dupe = comms[dupeId];
                        if(this.thiscontractcommodity[dupe.index].condition_id === ''){
                            this.thiscontractcommodity[dupe.index].condition_id = 1;
                        }
                        this.thiscontractcommodity[i].condition_id = dupe.condition == 2 ? 1 : 2;
                        let el = document.querySelector('#accordion-' + this.index + ' .condition-' + i);
                        if(el){
                            el.disabled = true;
                        }
                    }
                    else{
                        comms.push({'id': this.thiscontractcommodity[i].commodity_id, 'index': i, "condition": this.thiscontractcommodity[i].condition_id});
                    }
                }
            },
            setCalcUnits(comm){
                if(!this.tariffBased) comm.sales_price_calculation_unit_id = comm.purchase_price_calculation_unit_id;
            },

            forceUpdate(e){
                this.validate(e);
                this.autofillConditions();
                this.addContractCommodity();
                this.deleteContractCommodity(this.thiscontractcommodity.length - 1);
            }
            
        }
    }
</script>
<style>
#items option[disabled] { display: unset !important }
.commodity-column > div > div > div:first-child > select{margin-top: 0 !important}
.popover-bubble{
    display: none;
    position: absolute;
    width: 200px;
    border: 1px solid black;
    text-align: center;
    border-radius: 4px;
    background-color: white;
    z-index: 1001;
    transform: translate(-100px, 5px)
}
.popover-trigger:hover{ cursor: help }
.popover-trigger:hover+.popover-bubble{ display: block !important }
</style>