<template>
    <ul class="nav nav-tabs mb-3" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
            <button class="nav-link active" id="customer-tab" data-bs-toggle="tab" data-bs-target="#customer" type="button" role="tab" aria-controls="customer" aria-selected="true" @click="setTab(1)">{{'1 - ' + $t('sales.customer_carrier')}}</button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="item-tab" data-bs-toggle="tab" data-bs-target="#items" type="button" role="tab" aria-controls="profile" @click="setTab(2)">{{'2 - ' + $tc('sales.contract_items', 2)}}</button>
        </li>
    </ul>
    <div class="tab-content" id="myTabContent">

    <div class="tab-pane fade show active" id="customer" role="tabpanel" aria-labelledby="customer-tab">

        <h5 class="mt-4 mb-3 fw-bold">{{$tc('navigation.customers', 1)}}</h5>
    
        <FormItem id="customer_name" :label="$tc('navigation.customers', 2)" type="livesearch-list" v-model="contract.customers" :options="customers" 
                  sizeLabel="2" sizeItem="4" @add="checkStepOne" @delete="checkStepOne" />

        <FormItem type="radiogroup" v-model="contract.is_based_off_tariffs" :options="tariffBasedOptions" :label="$t('overview.type')" sizeItem="4" sizeLabel="2" @change="checkStepOne(true)" />

        <template v-if="contract.is_based_off_tariffs === 0">
        <div class="row my-3">
            <label class="col-sm-2 col-form-label col-form-label-sm">{{$t('sales.valid_from_to')}}</label>
            <div class="col-sm-2 has-validation start-date">
                <FormItem id="startdate" type="datepicker" v-model="contract.start_date" @onchange="validateDate" :extraOption="startDate" />
            </div>
            <div class="col-sm-2 has-validation end-date">
                <FormItem id="enddate" type="datepicker" v-model="contract.end_date" @onchange="validateDate" :extraOption="endDate" />
                <div class="invalid-feedback"> <i class="fa fa-warning"></i>&nbsp;<span id="edatespan">{{$t('errors.date_in_past')}}</span></div>
            </div>
        </div>

        <FormItem id="contract-carrier" :label="$tc('navigation.carriers', 2)" type="livesearch-list" v-model="contract.carriers" :options="carriers"
                  sizeLabel="2" sizeItem="4" @add="checkStepOne" @delete="checkStepOne" />
        </template>
        <template v-else-if="contract.is_based_off_tariffs === 1">
            <i class="fa fa-spinner fa-spin" v-if="loadingTariffs"></i>
            <div class="d-flex" v-else>
                <div style="width: 16.67%" class="py-2"> {{ $tc('navigation.tariffs', 2) }} </div>
                <div style="width: 82.33%">
                    <label v-for="tariff, tindex in tariffs" :key="tindex" class="row py-2" :class="{'border-top' : tindex}" :for="'tarr-' + tindex">
                        <div class="col-1" style="width: 5%">
                            <input type="checkbox" class="form-check-input" v-model="tariff.chosen" :id="'tarr-' + tindex" @change="setContractTariffs" />
                        </div>
                        <div class="col-1" style="width: 12%">{{tariff.number}}</div>
                        <div class="col-1">{{ $t('overview.' + (tariff.region_type_id == 2 ? 'deep_sea' : 'short_sea')) }}</div>
                        <div class="col-1">{{tariff.calcdata.office}}</div>
                        <div class="col-2" style="width: 20%">{{tariff.carrier.relation.name}}</div>
                        <div class="col-3" style="width: 30%">{{tariff.calcdata.region}}</div>
                        <div class="col-1" style="width: 15%">{{tariff.calcdata.validity}}</div>
                    </label>
                </div>
            </div>
            <div class="d-none">
                <FormItem id="contract-carrier" type="livesearch-list" v-model="contract.carriers" :options="carriers" />
            </div>
        </template>
    </div>

    <div class="tab-pane fade" id="items" role="tabpanel" aria-labelledby="item-tab">

        <h5 class="mt-4 mb-3 fw-bold">{{$tc('sales.contract_items', 2)}}</h5>

        <div class="accordion" id="contract-accordion" v-if="formActive">
            <div v-for="(contractitem,index) in contract.contract_items" :key="index" class="accordion-item" :id="'accordion-'+index">

                <div class="accordion-header d-flex" :id="'heading-'+index">
                    <button class="acc-header-item header-main accordion-button pe-0" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse-'+index" aria-expanded="true" :aria-controls="'collapse-'+index" @click.prevent="collapseOthers(index)">
                        <div v-if="isOneOfEachFieldFilledIn(contractitem)" class="d-flex w-100">
                            <div class="col-auto pe-2" style="width: 22%">{{getLoadingPortsString(contractitem, 'loading_port')}}</div>
                            <i class="col-auto pt-1 fa fa-arrow-right"></i>
                            <div class="col-3 ps-4">{{getLoadingPortsString(contractitem, 'discharge_port')}}</div>
                            <template v-if="contract.is_based_off_tariffs">
                                <div class="col-4">{{getCommodityNames(index)}}</div>
                                <div class="col-auto" style="width: 20%">{{contractitem.carrier}}</div>
                            </template>
                            <div v-else class="col-5">{{getCommodityNames(index)}}</div>
                        </div>
                        <span v-else>{{$t('sales.new_x', [$tc('sales.contract_items', 1)])}}</span>
                    </button>
                    <button type="button" class="acc-header-item header-remove-item" @click="contract.is_based_off_tariffs ? openAccordion($event, 1) : deleteContractitem(index)">
                        <i class="fal fa-trash-alt" v-show="!contract.is_based_off_tariffs"></i>
                    </button>
                    <button type="button" class="acc-header-item header-arrow" @click="openAccordion"></button>
                </div>

                <div class="accordion-collapse collapse show" :id="'collapse-'+index" :aria-labelledby="'heading-'+index" data-bs-parent="contract-accordion">
                    <div class="accordion-body">
                        <div class="row mb-1">
                            <label class="col-6">{{$tc('sales.loading_ports', 2)}}</label>
                            <label class="col-6">{{$tc('sales.discharge_ports', 2)}}</label>
                        </div>
                        <div class="row mb-3">
                            <div class="col-6">
                                <FormItem :id="'loadingport-'+index" type="livesearch-list" v-model="contractitem.loading_port" :options="ports" :content="contractitem.loading_port.name" :disabled="contract.is_based_off_tariffs" />
                            </div>
                            <div class="col-6">
                                <FormItem :id="'dischargeport-'+index" type="livesearch-list" v-model="contractitem.discharge_port" :options="ports" :content="contractitem.discharge_port.name" :disabled="contract.is_based_off_tariffs" />
                            </div>
                        </div>
                        <ContractcommodityFormComponent :initialContractcommodity="contractitem.contract_item_commodities" :index="index" :allCommodities="commodities"
                                                        :currencies="currencies" :calc_units="calc_units" :tariffBased="contract.is_based_off_tariffs" />
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-4 justify-content-center" v-if="!contract.is_based_off_tariffs">
            <a class="btn btn-sm btn-primary w-25" title="Click to add item" @click="addContractitem()">
                <span class="fa fa-plus"></span> &nbsp;{{$t('form.add', [$tc('sales.contract_items', 1)])}}
            </a>
        </div>
        
    </div>
    <Files :files="contract.file_info" @addFile="addFiles" parentItemName="contract" />
    </div>

</template>

<script>
    import commodityService from "@/services/CommodityService";
    import tariffService from "@/services/TariffService"
    import relationService from "@/services/RelationService"
    import fileService from "@/services/FileService"
    import portService from '@/services/PortService'
    import currencyService from "@/services/CurrencyService";
    import calculationunitService from "@/services/CalculationunitService";
    import ContractcommodityFormComponent from '../contractcommodity/ContractcommodityFormComponent.vue'
    import moment from 'moment'
    import store from '@/store/user'
    import dataStore from '@/store/data'
    import Files from '@/components/formpopup/Files.vue'
    export default {
        name: 'ContractForm',
        props: ['itemData'],
        components: {
            ContractcommodityFormComponent,
            Files
        },
        data() {
            return {
                type:'',
                itemId:'',
                contract: {
                    'name': null,
                    'customers': [],
                    'carriers': [],
                    'carrier_id': null,
                    'start_date': '',
                    'end_date': '',
                    'is_based_off_tariffs': null,
                    'file_info': [],
                    'contract_items': [{
                        'number': null,
                        'loading_port': [],
                        'discharge_port': [],
                        'contract_item_commodities': [{
                            'id': null,
                            'condition_id': '2',
                            'sales_price': '',
                            'sales_price_currency_id': window.defaultCurrency,
                            'purchase_price': '',
                            'purchase_price_currency_id': window.defaultCurrency,
                            'commodity_id': '',
                            'measurement_conditional_description': null,
                            'calculation_unit_id': null,
                            'currency_id': window.defaultCurrency,
                            'tariffs':[],
                            'commodities': []
                        }]
                    }],
                    
                },
                startDate: '',
                endDate: '',
                customers: [],
                carriers: [],
                ports: [],
                commodities: [],
                currencies:[],
                calc_units:[],
                tariffs: [],
                submitted: false,
                loading: false,
                loadingTariffs: false,
                invalid: {},
                savedTariffs: [],
                contractType:  this.$route.name.toLowerCase().replace('s',''),
                tariffBasedOptions: [{id: 1, name: this.$t('sales.tariff_based_contract')}, {id: 0, name: this.$t('sales.tariff_based_contract_not')}],
                measurementConditionalTags: ['', 'cm', 'cm', 'cm', 'kg', 'm²', 'm³', 'w/m'],
                conditionDirections: {'1': '>', '2': '<='},
                measurementConditionals: {},
                dataItemsLoaded: 0,
                dataItemsToLoad: 5,
                isCopying: false,
                isEditing: false,
                formActive: false
            }
        },
        watch: {
            itemData: function (val) {
                this.contract = val    
            }
            
        },
        methods: {
            active(value){
                this.formActive = value;
            },
            addFiles(fileInfo){
                if (this.contract.id) {
                    fileService.upload({"file_info": [fileInfo],"id": this.contract.id,"model": 'contract'}).then(response => {
                        this.contract.file_info.unshift(response.data); 
                    }).catch(error => {
                        console.log('error', error)
                    })
                } else {
                    this.contract.file_info.unshift(fileInfo); 
                }
            },
            validate(){
                let message = "";
                let valid = true;
                let required = document.querySelectorAll('#formModal .dp__input.is-invalid');

                //Check tab 1
                if(required.length > 0){
                    valid = false;
                    message = "You have filled in an invalid date";
                }
                if(!valid) {
                    alert(message);
                    this.setTab(1, true);
                    return valid;
                }

                //Check tab 2
                //Check selects and number fields
                required = document.querySelectorAll('#formModal input[type="number"], #formModal select');
                for(let field of required) {
                    if(!field.value){
                        valid = false;
                        field.classList.add("is-invalid");
                        message = this.$t('errors.empty_fields');
                    }
                    else{
                        field.classList.remove("is-invalid");
                    }
                }
                //Check ports
                required = document.querySelectorAll('#items .simple-typeahead+div');
                for(let column of required) {
                    if(column.childElementCount == 0){
                        valid = false;
                        message = "There are ports that are yet to be selected.";
                        column.previousSibling.querySelector('input').classList.add('is-invalid');
                    }
                }
                //Check if there is at least one item
                if(this.contract.contract_items.length == 0){
                    valid = false;
                    message = "There are no contract items.";
                }

                if(!valid) alert(message);
                if(valid){
                    for(let t in this.contract.tariffs){
                        if(this.contract.tariffs[t].id === undefined){
                            this.contract.tariffs[t] = {id: this.contract.tariffs[t]};
                        }
                    }
                }
                return valid;
            },
            validateDate(){
                let sdate = new Date(this.contract.start_date).getTime();
                let edate = new Date(this.contract.end_date).getTime();
                let sdateSpan = document.getElementById("edatespan");
                let isValid = true;
                if(sdate > edate){
                    isValid = false;
                    sdateSpan.innerHTML = this.$t('errors.date_inverse');
                }
                if(isValid){
                    document.querySelector(".start-date .dp__input_wrap").classList.remove('is-invalid');
                    document.querySelector(".end-date .dp__input_wrap").classList.remove('is-invalid');
                    sdateSpan.parentElement.classList.remove('d-block');
                }
                else{
                    document.querySelector(".start-date .dp__input_wrap").classList.add('is-invalid');
                    document.querySelector(".end-date .dp__input_wrap").classList.add('is-invalid');
                    sdateSpan.parentElement.classList.add('d-block');
                }
            },
            getData() {
                return this.contract;
            },
            setData(data) {
                if(data) this.contract = data;
                if(this.dataItemsLoaded < this.dataItemsToLoad) return;

                if(this.contract.file_info === undefined){
                    this.contract.file_info = [];
                }
                this.savedTariffs = [];
                if(this.isCopying){
                    if(this.contract.is_based_off_tariffs){
                        this.contract.contract_items = [{
                            'number': null,
                            'tariff_item_id': null,
                            'loading_port': [],
                            'discharge_port': [],
                            'contract_item_commodities': [{
                                'id': null,
                                'condition_id': '2',
                                'sales_price': '',
                                'sales_price_currency_id': window.defaultCurrency,
                                'purchase_price': '',
                                'purchase_price_currency_id': window.defaultCurrency,
                                'commodity_id': '',
                                'measurement_conditional_description': null,
                                'sales_calculation_unit_id': null,
                                'purchase_calculation_unit_id': null,
                                'currency_id': window.defaultCurrency,
                                'tariffs': [],
                                'commodities': [{id: ''}]
                            }]
                        }];
                        this.contract.tariffs = [];
                        this.contract.carriers = [];
                    }
                    this.isCopying = false;
                }
                this.contract.contract_items.forEach(ci => {
                    ci.contract_item_commodities.forEach(cic => {
                        this.savedTariffs.push(cic);
                    })
                })
                this.$nextTick( () => { 
                    this.setTab(1, true);
                    this.checkStepOne(true, true);
                    this.$forceUpdate();
                });
            },
            reset(isEditing = false, isCopying = false) {
                this.isEditing = isEditing;
                this.tariffs = [];
                if(this.dataItemsLoaded < this.dataItemsToLoad){
                    this.savedTariffs = [];
                    this.isCopying = isCopying;
                    this.loadData();
                    return;
                }
                else if(isCopying){
                    this.isCopying = true;
                }

                let cName = document.querySelector('[data-ref-xqf="customer_name"]');
                if (cName != null) cName.value = "";
                this.selectedItemIndex = 0;
                const quarter = moment().quarter(moment().quarter() + 1);
                this.contract = {
                    'name': null,
                    'customers': [],
                    'carrier_id': null,
                    'carriers': [],
                    'start_date': quarter.startOf('quarter').format('YYYY-MM-DD'),
                    'end_date': quarter.endOf('quarter').format('YYYY-MM-DD'),
                    'file_info': [],
                    'tariffs': [],
                    'is_based_off_tariffs': null,
                    'contract_items': [{
                        'number': null,
                        'tariff_item_id': null,
                        'loading_port': [],
                        'discharge_port': [],
                        'contract_item_commodities': [{
                            'id': null,
                            'condition_id': '2',
                            'sales_price': '',
                            'sales_price_currency_id': window.defaultCurrency,
                            'purchase_price': '',
                            'purchase_price_currency_id': window.defaultCurrency,
                            'commodity_id': '',
                            'measurement_conditional_description': null,
                            'sales_calculation_unit_id': null,
                            'purchase_calculation_unit_id': null,
                            'currency_id': window.defaultCurrency,
                            'tariffs': [],
                            'commodities': [{id: ''}]
                        }]
                    }],
                    
                },
                this.contractType =  this.$route.name.toLowerCase().replace('s','');
                this.loading = false
                this.invalid = {}
                this.setTab(1, true);
                this.checkStepOne();
            },
            checkStepOne(searchTariffs = false, hardSearch = false){
                if(this.contract.is_based_off_tariffs !== null) this.contract.is_based_off_tariffs = Number(this.contract.is_based_off_tariffs);
                const isTariffBased = this.contract.is_based_off_tariffs === 1, notTariffBased = this.contract.is_based_off_tariffs === 0;
                let canGoToStepTwo = this.contract.customers.length > 0 && ((notTariffBased && this.contract.carriers.length > 0) || (isTariffBased && this.contract.tariffs.length > 0));
                document.getElementById('nextbutton').disabled = !canGoToStepTwo;
                document.getElementById('item-tab').disabled = !canGoToStepTwo;
                if(searchTariffs === true && isTariffBased && (!this.tariffs.length || hardSearch)){
                    this.loadingTariffs = true;
                    tariffService.index(1, {'forContract': true}).then(response => {
                        const data = response.data;
                        let allTariffs = [];
                        data.forEach(dat => {
                            if(hardSearch){
                                dat.chosen = this.contract.tariffs.find(tarr => tarr.id == dat.id) != null;
                            }
                            dat.carriers.forEach(carr => {
                                const newTariff = dat;
                                newTariff.carrier = carr;
                                delete newTariff.carriers;
                                newTariff.calcdata = this.calcTariffData(newTariff);
                                allTariffs.push(newTariff);
                            })
                        })
                        this.tariffs = allTariffs;
                        if(this.hardSearch){
                            this.checkStepOne();
                        }
                    }).catch(e => console.log(e))
                    .finally(() => this.loadingTariffs = false);
                }
            },

            addContractitem() {
                //Close open accordion items
                let openAccordionItems = document.querySelectorAll(".accordion-button:not(.collapsed)");
                openAccordionItems.forEach(i => {i.click()});

                this.contract.contract_items.push({
                    'number': null,
                    'loading_port': [],
                    'discharge_port': [],
                    'contract_item_commodities': [{
                        'id': null,
                        'condition_id': '2',
                        'sales_price': '',
                        'sales_price_currency_id': window.defaultCurrency,
                        'purchase_price': '',
                        'purchase_price_currency_id': window.defaultCurrency,
                        'commodity_id': '',
                        'measurement_conditional_description': null,
                        'sales_calculation_unit_id': null,
                        'purchase_calculation_unit_id': null,
                        'currency_id': window.defaultCurrency,
                        'tariffs':[],
                        'commodities': [{id: ''}]
                    }],

                });

            },
            deleteContractitem(index) {
                if(confirm("Are you sure you want to delete this contract item?")) this.contract.contract_items.splice(index, 1);
            },

            setTab(tabId, shouldClickTab = false){
                if(shouldClickTab){
                    document.querySelectorAll(".nav-link")[tabId - 1].click();
                }
                document.getElementById('savebutton').style.display = tabId == 2 ? 'block' : 'none';
                document.getElementById('nextbutton').style.display = tabId == 2 ? 'none' : 'block';

                if(this.contract.customers.length){
                    let cName = [];
                    let cNames = document.querySelectorAll('#customer_name_wrapper + div span');
                    cNames.forEach(name => cName.push(name.innerHTML));
                    cName = cName.length > 1 ? (cName[0] + " +" + (cName.length - 1)) :  cName.join("");
                    const el = document.querySelector('#contract-carrier_wrapper .simple-typeahead-input');
                    let crName = (this.contract.is_based_off_tariffs || !el) ? [] : el.value;
                    if(this.contract.is_based_off_tariffs){
                        let carrierNames = this.tariffs.filter(tarr => tarr.chosen).map(tarr => tarr.carrier.relation.name);
                        crName = carrierNames.length > 1 ? (carrierNames[0] + " +" + (carrierNames.length - 1)) :  carrierNames.join("");
                    }
                    let period = this.formatDate(this.contract.start_date, 'shortDate') + " / " + this.formatDate(this.contract.end_date, 'shortDate');
                    const cNumber = this.contract.id ? (this.contract.number + " - ") : "";
                    document.getElementById("detailsSpan").innerHTML = ": " + cNumber + cName + " - " + crName + " - " + period;
                    this.$forceUpdate();
                }
                else {
                    document.getElementById("detailsSpan").innerHTML = "";
                }
            },

            openAccordion(e, steps = 2){
                steps == 2 ? e.target.previousSibling.previousSibling.click() : e.target.previousSibling.click();
            },
            collapseOthers(index){
                let i = 0;
                document.querySelectorAll(".header-main").forEach(el => {
                    if (i != index && el.getAttribute("aria-expanded") == "true") el.click();
                    i++;
                })
            },
            isOneOfEachFieldFilledIn(item) { 
                return item.loading_port.length > 0 && item.discharge_port.length > 0 && item.contract_item_commodities.length > 0
            },
            getLoadingPortsString(item, portname){
                let pString = "";
                let pAmount = 0;
                let pExtra = 0;
                item[portname].forEach(port => {
                    if(pAmount == 0)
                        pString += port.name;
                    else if((pString.length + port.name.length) <= 20)
                        pString += (', ' + port.name);
                    else
                        pExtra += 1;
                    pAmount++;
                });
                if(pExtra > 0) pString += (" +" + pExtra);
                return pString;
            },
            getCommodityNames(index){
                let cString = "";
                let cAmount = 0;
                let cExtra = 0;
                let comms = document.querySelectorAll("#collapse-"+index+" .commodity-name");
                comms.forEach(el => {
                    let value = el.options[el.selectedIndex] ? el.options[el.selectedIndex].text : '';
                    if(value == this.$t('form.select'))
                        return;
                    if(cAmount == 0)
                        cString += value;
                    else if((cString.length + value.length) <= 30)
                        cString += (', ' + value);
                    else
                        cExtra += 1;
                    cAmount++;
                });
                if(cExtra > 0) cString += (" +" + cExtra);
                return cString;
            },
            calcTariffData(data){
                let returnData = {};
                let key = data.region_type_id == 1 ? 'short_sea_region' : 'deep_sea_region';
                let allRegionNames = [];
                let allRegionIds = [];
                function lookForPortRegions(port){
                    if(port[key]){
                        const id = port[key].id;
                        const name = port[key].name;
                        if(!allRegionIds.includes(id) ) {
                            allRegionNames.push(name);
                            allRegionIds.push(id);
                        }
                    }
                }
                data.tariff_items.forEach(item => {
                    item.loading_port.forEach(port => lookForPortRegions(port));
                    item.discharge_port.forEach(port => lookForPortRegions(port));
                });
                returnData.region = allRegionNames.join(' - ');
                returnData.office =  data.office_creator_id == this.$parent.$parent.get('auth_office') ? this.$t('overview.direct') : ('NMT' + data.office.relation.country.iso)
                const sdate = moment(data.start_date).locale(this.$i18n.locale);
                const edate = moment(data.end_date).locale(this.$i18n.locale);
                const sday = sdate.date();
                const smonth = sdate.month() + 1;
                const eday = edate.date();
                const emonth = edate.month() + 1;
                const year = edate.year() - 2000;
                let validity = '';
                if(sday == 1 && smonth == 1 && eday == 31 && emonth == 3){
                    validity = "Q1 '" + year
                }
                if(sday == 1 && smonth == 4 && eday == 30 && emonth == 6){
                    validity = "Q2 '" + year
                }
                if(sday == 1 && smonth == 7 && eday == 30 && emonth == 9){
                    validity = "Q3 '" + year
                }
                if(sday == 1 && smonth == 10 && eday == 31 && emonth == 12){
                    validity = "Q4 '" + year
                }
                const smonthtext = sdate.format("MMM").substring(0, 3);
                const emonthtext = edate.format("MMM").substring(0, 3);
                returnData.validity = validity ? validity : (smonthtext + ' ' + sday + ' - ' + emonthtext + ' ' + eday + " '" + year);
                return returnData;
            },
            setContractTariffs(){
                const arr = this.tariffs.filter(tarr => tarr.chosen).map(tarr => tarr.id);
                this.contract.tariffs = arr.length ? arr : null;
                this.generateContractBasedOnTariffs();
                this.contract.carrier_id = (this.contract.carriers && this.contract.carriers[0]) ? this.contract.carriers[0].id : null;
                this.checkStepOne();
            },
            generateContractBasedOnTariffs(){
                this.contract.contract_items = [];
                this.contract.carriers = [];
                const officeId = store.getters.getCompanyId;
                let validFrom = '2100-01-01';
                let validTo = '2000-01-01';
                let hasTariffs = false;
                this.tariffs.forEach(tariff => {
                    if(tariff.chosen){
                        hasTariffs = true;
                        tariff.tariff_items.forEach(item => {
                            const newContractItem = {
                                'number': null,
                                'tariff_item_id': item.id,
                                'loading_port': [...item.loading_port],
                                'discharge_port': [...item.discharge_port],
                                'carrier': tariff.carrier.relation.name,
                                'contract_item_commodities': []
                            };
                            if(!this.contract.carriers.find(carr => carr.id == tariff.carrier.id)) this.contract.carriers.push(tariff.carrier);
                            if(tariff.start_date < validFrom) validFrom = tariff.start_date;
                            if(tariff.end_date > validTo) validTo = tariff.end_date;
                            item.tariff_item_commodities.forEach(comm => {
                                const rates = comm.tariff_item_commodity_market_levels.find(level => level.office_id == officeId);
                                const carrier = comm.tariff_item_commodity_carriers.find(carr => carr.carrier_id == tariff.carrier.id);
                                if(!rates || !carrier) return;
                                comm.tariff_item_commodity_commodities.forEach(commod => {
                                    const newComm = {
                                        'id': null,
                                        'condition_id': commod.condition_id ? commod.condition_id : '1',
                                        'sales_price': rates.amount,
                                        'sales_price_currency_id': rates.currency_id,
                                        'sales_price_calculation_unit_id': rates.calculation_unit_id,
                                        'purchase_price': carrier.buying_rate,
                                        'purchase_price_currency_id': carrier.buying_rate_currency_id,
                                        'purchase_price_calculation_unit_id': carrier.buying_rate_calculation_unit_id,
                                        'commodity_id': '',
                                        'is_pro_rata': comm.is_pro_rata,
                                        'currency_id': rates.currency_id,
                                        'tariff_item_commodity_id': comm.id,
                                        'tariffs': [],
                                        'commodities': [{ id: commod.commodity.id,  name: commod.commodity.name }]
                                    };
                                    if(commod.tariff_item_commodity_measurement_conditionals.length > 0){
                                        newComm.measurement_conditional_description = [];
                                        commod.tariff_item_commodity_measurement_conditionals.forEach(cond => {
                                            newComm.measurement_conditional_description.push(this.measurementConditionals[cond.measurement_conditional_id] + ' ' + this.conditionDirections[cond.operator]
                                                                       + ' ' + cond.value + this.measurementConditionalTags[cond.measurement_conditional_id]);
                                        })
                                        newComm.measurement_conditional_description = newComm.measurement_conditional_description.join('<br />');
                                    }
                                    const oldTariff = this.savedTariffs.find(st => st.purchase_price == newComm.purchase_price && st.commodities[0].id == commod.commodity.id &&
                                                           st.condition_id == newComm.condition_id && st.measurement_conditional_description == newComm.measurement_conditional_description &&
                                                           st.purchase_price_currency_id == newComm.purchase_price_currency_id && st.purchase_price_calculation_unit_id == newComm.purchase_price_calculation_unit_id);
                                    if(oldTariff){
                                        newComm.sales_price = oldTariff.sales_price;
                                        newComm.sales_price_currency_id = oldTariff.sales_price_currency_id;
                                        newComm.sales_price_calculation_unit_id = oldTariff.sales_price_calculation_unit_id;
                                    }
                                    else{
                                        this.savedTariffs.push(newComm);
                                    }
                                    newContractItem.contract_item_commodities.push(newComm);
                                });
                            });
                            this.contract.contract_items.push(newContractItem);
                        });
                    }
                });
                if(hasTariffs){
                    this.contract.start_date = validFrom;
                    this.contract.end_date = validTo;
                }
            },
            hardCopy(obj){
                return JSON.parse(JSON.stringify(obj));
            },
            checkData(){
                if(this.dataItemsLoaded >= this.dataItemsToLoad){
                    if(this.isEditing)
                        this.setData(false);
                    else
                        this.reset();
                }
            },
            loadData(){
                relationService.index(null, null, 'customer').then(response => {
                    this.customers = response.data;
                    this.dataItemsLoaded++;
                    this.checkData();
                }).catch(error => { console.log('error', error) })
                relationService.index(null, null, 'carrier').then(response => {
                    this.carriers = response.data //todo
                    this.carriers.push({id: -1, name: 'Other', carrier: {'preferred': true, 'logo': ''}});
                    this.dataItemsLoaded++;
                    this.checkData();
                }).catch(error => { console.log('error', error) })
                portService.index().then(response => {
                    this.ports = response.data //todo
                    this.dataItemsLoaded++;
                    this.checkData();
                }).catch(error => { console.log('error', error) })
                commodityService.index().then(response => {
                    this.commodities = response.data //todo
                    this.dataItemsLoaded++;
                    this.checkData();
                }).catch(error => { console.log('error', error) })
                currencyService.index().then(response => {
                    this.currencies = response.data
                    window.defaultCurrency = this.currencies.filter(currency => currency.is_default == 1)[0].id;
                    this.dataItemsLoaded++;
                    this.checkData();
                }).catch(error => { console.log('error', error) })
                calculationunitService.index().then(response => {
                    this.calc_units = response.data.filter(unit => unit.surcharge_only == false);
                }).catch(error => { console.log('error', error) })
            }
        },
        mounted() {
            const quarter = moment().quarter(moment().quarter() + 1);
            this.startDate = quarter.startOf('quarter').format('YYYY-MM-DD'); 
            this.endDate = quarter.endOf('quarter').format('YYYY-MM-DD');
            const mconds = dataStore.getters.getMeasurementConditionals;
            mconds.forEach(cond => this.measurementConditionals[cond.id] = cond.name);
        }
    }
</script>

<style scoped>
    #extraButtonStep2 {position: absolute; left: 1rem; bottom: 1rem}
    .accordion-item {
        margin-bottom: 1rem;
        border: 2px solid #0d6efd;
        border-radius: 6px
    }
    .acc-header-item { border: none }
    .accordion-header { width: 100%; }
    .accordion-button::after {
        background-image: none;
        transform: none
    }
    .acc-header-item.header-main {
        width: calc(100% - 100px);
        box-shadow: none !important;
        border-radius: 0px !important;
        transition: background-color .15s
    }
    .acc-header-item.header-remove-item, .acc-header-item.header-arrow {
        width: 50px;
        transition: background-color .15s
    }
    .accordion-button:not(.collapsed), .accordion-button:not(.collapsed) + .header-remove-item, .accordion-button:not(.collapsed) + * + .header-arrow {
        background-color: #0d6efd;
        color: white
    }
    .accordion-button.collapsed, .accordion-button.collapsed + .header-remove-item, .acc-header-item.collapsed + * + .header-arrow {
        background-color: white;
        color: #0d6efd;
        font-weight: bold;
    }
    .acc-header-item.header-arrow::after {
        display: block;
        content: '\276F'
    }
    .accordion-button:not(.collapsed) + * + .acc-header-item.header-arrow::after {
        rotate: 90deg
    }

</style>