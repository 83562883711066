<template>
    <Overview 
        :moduleName="this.formatModuleName(this.$route.name, this.$i18n, false)"
        :moduleNamePlural="$t('overview.my_x', [this.formatModuleName(this.$route.name, this.$i18n)])"
        :sort="'number'"
        :sortOrder="-1"
        :itemService=itemService
        :itemFormRef=itemFormRef
        :modalSize="'modal-xl'"
        :modalWidth="'1400px'"
        :modalHeight="'90%'"
        :showCopy="true"
        :selectFields=selectFields
        :filterOptions="filterOptions"
    >
        <template #columns>
            <Column field="number" :header="$t('overview.number')" style="width: 10rem" :sortOrder="-1"></Column>
            <Column field="customers.relation.name" :header="$tc('navigation.customers', 2)" :sortable="false">
                <template #body="slotProps">
                    {{getCustomerNames(slotProps.data.customers)}}
                </template>
            </Column>
            <Column field="carrier.relation.name" :header="$tc('navigation.carriers')" style="width: 20%">
                <template #body="slotProps">
                    {{getCarrierNames(slotProps.data.carriers, slotProps.data.carrier)}}
                </template>
            </Column>
            <Column field="start_date" :header="$t('overview.start_date')" style="width: 10rem" :sortOrder="-1">
                <template #body="slotProps">
                    {{formatDate(slotProps.data.start_date, 'shortDate')}}
                </template>
            </Column>
            <Column field="end_date" :header="$t('overview.end_date')" style="width: 10rem" :sortOrder="-1">
                <template #body="slotProps">
                    {{formatDate(slotProps.data.end_date, 'shortDate')}}
                </template>
            </Column>
        </template>

        <template #form>
            <ContractForm ref="ItemForm" :itemData="item" />
        </template>

        <template #modal-footer>
            <button id="nextbutton" type="button" class="btn btn-success" @click.prevent="next">&gt;&ensp;{{$t('form.continue_to_step')}}<span id="nextStep">2</span></button>
        </template>
    </Overview>
</template>

<script>
import Overview from '@/components/Overview.vue';
import Column from '@/components/column';
import ContractForm from '@/components/contract/ContractForm.vue';
import contractService from '@/services/ContractService';
import customerService from '@/services/CustomerService';
import carrierService from '@/services/CarrierService';

export default {
    components: {
        Overview,
        Column,
        ContractForm
    },
    data() {
        return {
            item: {},
            itemService: contractService,
            itemFormRef: null,
            filterOptions: [
                {
                    field: 'customer_id',
                    service: customerService,
                    name: this.$t('navigation.customers')
                },
                {
                    field: 'carrier_id',
                    service: carrierService,
                    name: this.$t('navigation.carriers')
                }
            ],
            selectFields: [
                "id",
                "number",
                "start_date",
                "end_date",
                "carrier_id",
                {
                    "customers": [
                        "relation_id",
                        "contract_id",
                        {
                            "relation": [
                                "id",
                                "name"
                            ]
                        }
                    ]
                },
                {
                    "carrier": [
                        "id",
                        "relation_id",
                        {
                            "relation": [
                                "id",
                                "name"
                            ]
                        }
                    ]
                }
            ]
        }
    },
    methods: {
        next() {
            let tabs = document.querySelectorAll("#formModal ul.nav-tabs .nav-link");
            let next = false;
            for(let tab of tabs) {
                if(next) {
                    tab.click();
                    break;
                }
                if(tab.className == "nav-link active") next = true;
                
            }
        },
        getCustomerNames(customers){
            let values = [];
            if(!customers) return "-";
            customers.forEach(customer => {
                values.push(customer.relation.name);
            });
            return values.join(", ");
        },
        getCarrierNames(carriers, secondary){
            if(!carriers) return "-";
            if(!carriers[0]) return secondary.relation.name;
            const firstCarrier = carriers[0].relation.name;
            return carriers.length > 1 ? (firstCarrier + ' +' + (carriers.length - 1)) : firstCarrier;
        }
    },
    mounted() {
        this.itemFormRef = this.$refs.ItemForm;
    }
}
</script>